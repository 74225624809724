import { Component, OnDestroy, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { removeProperties } from '@app/shared/utilities';
import {
  AppStoreState,
  FparReportActions,
  FparReportSelectors,
  GrantActions,
  GrantProfileCoverSheetActions,
  GrantProfileCoverSheetSelectors,
  GrantSelectors,
} from '@app/store';
import {
  FparDataSummary,
  GrantCoverSheetAdminProjectDirector,
  GrantCoverSheetContactPerson,
  GrantNumberOfSites,
} from '@core/models';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-print-submission',
    templateUrl: './print-submission.component.html',
    styleUrls: ['./print-submission.component.scss'],
    standalone: false
})
export class PrintSubmissionComponent implements OnDestroy, OnInit {
  public acceptedDate: string;
  public columnTotals: any[] = [];
  public dataReportingPeriodYear: string;
  public fparReportData: FparDataSummary;
  public grantCoverSheetContact: GrantCoverSheetContactPerson;
  public grantCoverSheetDirector: GrantCoverSheetAdminProjectDirector;
  public grantNumberofSites: GrantNumberOfSites;
  public loading$: Observable<boolean>;
  public loadingText = 'Report loading...';
  public otherRevenue: any[] = [];
  public otherRevenueIds = [6, 7, 8, 9, 10, 11, 12];
  public otherRevenueRows: any[] = [];
  public otherRevenueTotal: number;
  public overallTotal = 0;
  public revenueTotal: number;
  public rowTotals: any[] = [];
  public submissionComments;
  public thirdPartyPayerIds = ['3a', '3b', '3c', '3d', '3e'];
  public thirdPartyRevenueNotPrepaid: any[] = [];
  public thirdPartyRevenuePrepaid: any[] = [];
  public thirdPartyRevenueTotals = { prepaid: [], notPrepaid: [] };
  public today = new Date();
  public totalPaymentForServices: number;
  public totalTotals: any[] = [];

  private destroyed$ = new Subject<boolean>();
  private excludedColumns: string[] = [];
  private fparReportData$: Observable<FparDataSummary>;
  private fparReportDataLoading$: Observable<boolean>;
  private grantCoverSheetContactData$: Observable<GrantCoverSheetContactPerson>;
  private grantCoverSheetContactDataLoading$: Observable<boolean>;
  private grantCoverSheetDirectorData$: Observable<GrantCoverSheetAdminProjectDirector>;
  private grantCoverSheetDirectorDataLoading$: Observable<boolean>;
  private numberOfSites$: Observable<GrantNumberOfSites>;
  private numberOfSitesLoading$: Observable<boolean>;
  private selectedGrantLoading$: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store$: Store<AppStoreState.State>,
    private datePipe: DatePipe,
  ) {
    this.fparReportData$ = this.store$.select(
      FparReportSelectors.selectFparReportData,
    );
    this.fparReportDataLoading$ = this.store$.select(
      FparReportSelectors.selectGetFparReportLoading,
    );
    this.grantCoverSheetContactData$ = this.store$.select(
      GrantProfileCoverSheetSelectors.selectContactPerson,
    );
    this.grantCoverSheetContactDataLoading$ = this.store$.select(
      GrantProfileCoverSheetSelectors.selectContactPersonLoading,
    );
    this.grantCoverSheetDirectorData$ = this.store$.select(
      GrantProfileCoverSheetSelectors.selectAdminProjectDirector,
    );
    this.grantCoverSheetDirectorDataLoading$ = this.store$.select(
      GrantProfileCoverSheetSelectors.selectAdminProjectDirectorLoading,
    );
    this.numberOfSites$ = this.store$.select(
      GrantSelectors.selectNumberOfSites,
    );
    this.numberOfSitesLoading$ = this.store$.select(
      GrantSelectors.selectNumberOfSitesLoading,
    );
    this.selectedGrantLoading$ = this.store$.select(
      GrantSelectors.selectSelectedGrantLoading,
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.loading$ = combineLatest([
      this.fparReportDataLoading$,
      this.grantCoverSheetContactDataLoading$,
      this.grantCoverSheetDirectorDataLoading$,
      this.numberOfSitesLoading$,
      this.selectedGrantLoading$,
    ]).pipe(map((loading) => loading.some((l) => l)));

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        if (params && params.grantId) {
          this.fetchData(
            Number(params.grantId),
            Number(params.reportingPeriodId),
          );
        }
      });

    this.numberOfSites$
      .pipe(
        filter((numberOfSites) => !!numberOfSites),
        takeUntil(this.destroyed$),
      )
      .subscribe((numberOfSites) => {
        this.grantNumberofSites = numberOfSites;
      });

    this.fparReportData$
      .pipe(
        filter((fparReportData) => !!fparReportData),
        takeUntil(this.destroyed$),
      )
      .subscribe((fparReportData) => {
        this.fparReportData = fparReportData;
        this.dataReportingPeriodYear = fparReportData.reportingPeriodName
          .split(' ')
          .pop();

        this.excludedColumns = fparReportData.fparTables[0].columns
          .filter((column) => column.isId || column.isLabel || column.isTotal)
          .map((column) => column.column);

        // format accepted date
        this.acceptedDate =
          this.datePipe.transform(fparReportData.submittedDate, 'longDate') ||
          'NOT YET ACCEPTED';

        // Set row totals
        this.rowTotals = fparReportData.fparTables.map((fparTable) =>
          fparTable.rows
            .map((row) => removeProperties(row, ...this.excludedColumns))
            .map((row) =>
              Object.keys(row).length
                ? Object.values(row)
                    .map((value) =>
                      typeof value === 'string'
                        ? parseFloat(value.toString())
                        : value,
                    )
                    .reduce(this.simpleAddReducer)
                : 0,
            ),
        );

        this.totalTotals = this.rowTotals.map((row) =>
          row.reduce(this.simpleAddReducer),
        );

        // get array of column subtotals
        fparReportData.fparTables.map((fparTable) => {
          const rowColumns = fparTable.rows
            .map((row) => removeProperties(row, ...this.excludedColumns))
            .map((key) => Object.keys(key));

          const columnKeys = Array.from(new Set(rowColumns[0].map((s) => s)));

          const rows = fparTable.rows.map((row) =>
            removeProperties(row, ...this.excludedColumns),
          );
          for (const i of columnKeys) {
            const subTotal = rows
              .map((key) => key[i])
              .reduce(this.simpleAddReducer);
            this.columnTotals.push(subTotal);
          }
        });
        this.thirdPartyRevenuePrepaid = fparReportData.fparTables[12].rows
          .filter((row) => this.thirdPartyPayerIds.includes(row.rowId))
          .map((row) => +row.prepaid);
        this.thirdPartyRevenueNotPrepaid = fparReportData.fparTables[12].rows
          .filter((row) => this.thirdPartyPayerIds.includes(row.rowId))
          .map((row) => +row.notPrepaid);

        // get array of third party subtotals
        fparReportData.fparTables[12].rows.map((row) =>
          +row.rowId >= 6 ? this.otherRevenue.push(+row.amount) : null,
        );

        this.otherRevenueRows = fparReportData.fparTables[12].rows.filter(
          (row) => +row.rowId > 11,
        );

        this.thirdPartyRevenueTotals.prepaid =
          this.thirdPartyRevenuePrepaid.reduce(this.simpleAddReducer);

        this.thirdPartyRevenueTotals.notPrepaid =
          this.thirdPartyRevenueNotPrepaid.reduce(this.simpleAddReducer);

        this.totalPaymentForServices =
          this.thirdPartyRevenuePrepaid.reduce(this.simpleAddReducer) +
          this.thirdPartyRevenueNotPrepaid.reduce(this.simpleAddReducer) +
          +fparReportData.fparTables[12].rows[1].amount;

        this.otherRevenueTotal = this.otherRevenue.reduce(
          this.simpleAddReducer,
        );

        this.revenueTotal =
          this.otherRevenueTotal +
          this.totalPaymentForServices +
          +fparReportData.fparTables[12].rows[0].amount +
          +fparReportData.fparTables[12].rows[1].amount;
      });

    this.grantCoverSheetContactData$
      .pipe(
        filter((fparReportData) => !!fparReportData),
        takeUntil(this.destroyed$),
      )
      .subscribe((grantCoverSheetContact) => {
        this.grantCoverSheetContact = grantCoverSheetContact;
      });

    this.grantCoverSheetDirectorData$
      .pipe(
        filter((fparReportData) => !!fparReportData),
        takeUntil(this.destroyed$),
      )
      .subscribe((grantCoverSheetDirector) => {
        this.grantCoverSheetDirector = grantCoverSheetDirector;
      });
  }

  public removeDecimal(value: any): string {
    const text = value?.toString();
    if (!text) {
      return '';
    }
    const lastDecimalIndex = text.lastIndexOf('.');

    return lastDecimalIndex !== -1 ? text.slice(0, lastDecimalIndex) : text;
  }

  private fetchData(grantId: number, reportingPeriodId?: number): void {
    this.store$.dispatch(
      FparReportActions.getFparReport({ grantId, reportingPeriodId }),
    );
    this.store$.dispatch(
      GrantProfileCoverSheetActions.getContactPerson({ grantId }),
    );
    this.store$.dispatch(
      GrantProfileCoverSheetActions.getAdminProjectDirector({ grantId }),
    );
    this.store$.dispatch(GrantActions.getNumberOfSites({ grantId }));
  }

  private simpleAddReducer(previous: number, current: number): number {
    return previous + current;
  }
}
